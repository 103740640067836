import React from 'react'
import Heading from './heading'
import Paragraph from './paragraph'

export const H1 = ({ children, ...props }) => <Heading level="1" {...props}>{children}</Heading>
export const H2 = ({ children, ...props }) => <Heading level="2" {...props}>{children}</Heading>
export const H3 = ({ children, ...props }) => <Heading level="3" {...props}>{children}</Heading>
export const H4 = ({ children, ...props }) => <Heading level="4" {...props}>{children}</Heading>
export const H5 = ({ children, ...props }) => <Heading level="5" {...props}>{children}</Heading>
export const H6 = ({ children, ...props }) => <Heading level="6" {...props}>{children}</Heading>
export const H7 = ({ children, ...props }) => <Heading level="7" {...props}>{children}</Heading>

export const P = ({ children, ...props }) => <Paragraph {...props}>{children}</Paragraph>
