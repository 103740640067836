import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { concatClassName } from '../util'

import { graphql, StaticQuery, Link } from 'gatsby'
import { Heading } from './typography'

export const Header = ({ title, titleLink, preposition = 'by', className }) => (
  <StaticQuery
    query={headerQuery}
    render={data => {
      const { author, social } = data.site.siteMetadata

      const titleElement = <Heading level="3" className="mr-2">{title}</Heading>
      const suffixElement = <Heading level="6">{preposition} {author}</Heading>

      return (
        <header className={concatClassName([className, "px-8 py-2"])}>
          <div className="container mx-auto flex flex-col lg:flex-row items-center lg:justify-between">
            <div to="/" className="mb-4 lg:mb-0">
              {titleLink ? (
                <span className="flex items-baseline">
                  <Link to={titleLink}>{titleElement}</Link>
                  <Link to="/">{suffixElement}</Link>
                </span>
                ) : (
                <Link to="/" className="flex items-baseline">
                  {titleElement}
                  {suffixElement}
                </Link>
              )}
            </div>
            <ul className="flex -mx-2 lg:mx-0">
              <li>
                <a href={`https://twitter.com/${social.twitter}`}>
                  Twitter
                </a>
              </li>
              <li>
                <a href={`https://github.com/${social.github}`}>Github</a>
              </li>
              <li>
                <a href={`https://instagram.com/${social.instagram}`}>
                  Instagram
                </a>
              </li>
              <li>
                <a href={`https://linkedin.com/in/${social.linkedin}`}>
                  LinkedIn
                </a>
              </li>
            </ul>
          </div>
        </header>
      )
    }}
  />
)

export default styled(Header)`
  li:not(:last-of-type) {
    ${tw`mr-4`}
  }
`

const headerQuery = graphql`
  query HeaderQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        title
        author
        social {
          github
          instagram
          linkedin
          twitter
        }
      }
    }
  }
`