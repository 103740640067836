import React from 'react'
import styled from 'styled-components'

import { capitalise } from '../util'
import Header from './header'

export const Layout = ({ title = '', titleLink, preposition, header = true, footer = true, children, className }) => (
  <div className={`min-h-screen flex flex-col font-body text-gray-900 ${className}`}>
    {header && <Header title={capitalise(title)} titleLink={titleLink} preposition={preposition} />}
    <main className="flex-1 mt-8">{children}</main>
    {footer && (
      <footer className="px-8 py-4">
        <div className="max-w-2xl mx-auto text-center">
          <small className="block">
            &copy; {new Date().getFullYear()}, These pixels lovingly crafted in small batches from sunny Cork.
          </small>
          <small className="block">
            Happily built with <a href="https://www.tailwindcss.com" className="text-gray-600">Tailwind</a> and <a href="https://www.gatsbyjs.org" className="text-gray-600">Gatsby</a>.
          </small>
        </div>
      </footer>
    )}
  </div>
)

export default styled(Layout)``
