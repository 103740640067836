export const concatClassName = array => array.filter(className => className !== '').join(' ')

export const capitalise = string => string.replace(/[a-zA-Z]/, letter => letter.toUpperCase())

export const pickRandom = (array, number, chosen = []) => {
  const getRandomIndex = (range = 1) => Math.floor(Math.random() * range)

  if (number <= 0) return []

  chosen.push(...array.splice(getRandomIndex(array.length -1), 1))

  if (chosen.length < number) {
    return pickRandom(array, number, chosen)
  }
  return chosen
}

export const randomColors = (number) => {
  const colors = ['red', 'orange', 'yellow', 'green', 'teal', 'blue', 'indigo', 'purple', 'pink']
  return pickRandom(colors, number)
}

export const truncateAst = (ast, tag, max) => {
  const { children } = ast.children.reduce(
    (acc, node) => {
      if (max === 0) return []
      if (acc.count < max) {
        acc.children.push(node)
      }
      if (node.type === "element" && node.tagName === tag) {
        acc.count += 1
      }
      return acc
    },
    {
      count: 0,
      children: [],
    }
  )
  return {
    ...ast,
    children,
  }
}