import React from 'react'
import styled from 'styled-components'
import { concatClassName } from '../../util/index'

export const Heading = ({ level, responsive = true, children, className, ...props }) => {
  const Tag = 'h' + level

  className = concatClassName([
    className,
    'font-display font-medium leading-tight',
    Number(level) === 1 ? responsive ? 'text-4xl sm:text-5xl md:text-6xl' : 'text-6xl' : '',
    Number(level) === 2 ? responsive ? 'text-3xl sm:text-4xl md:text-5xl' : 'text-5xl' : '',
    Number(level) === 3 ? responsive ? 'text-2xl sm:text-3xl md:text-4xl' : 'text-4xl' : '',
    Number(level) === 4 ? responsive ? 'text-xl sm:text-2xl md:text-3xl' : 'text-3xl' : '',
    Number(level) === 5 ? responsive ? 'text-lg sm:text-xl md:text-2xl' : 'text-2xl' : '',
    Number(level) === 6 ? responsive ? 'text-base sm:text-lg md:text-xl' : 'text-xl' : '',
    Number(level) === 7 ? responsive ? 'text-sm sm:text-base md:text-lg' : 'text-lg' : ''
  ])

  return <Tag className={className} {...props}>{children}</Tag>
}

export default styled(Heading)``
